import firebase from "firebase/app";
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { initializeApp } from "firebase/app";
// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyDZd5BbJTrF6n1CIdeGKe-ByUqDxKv5b1k",
  authDomain: "riccopy-80c7b.firebaseapp.com",
  projectId: "riccopy-80c7b",
  storageBucket: "riccopy-80c7b.appspot.com",
  messagingSenderId: "414304225029",
  appId: "1:414304225029:web:0f8b08d87755be5994996c",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
