import React, { useState, useEffect } from "react";
import { db } from "./firebase"; // Firebase Firestore instance
import { collection, getDocs } from "firebase/firestore";
import Modal from "react-modal";
import "./App.css";
import logo1 from "./dex-screener-logo-png_seeklogo-527276.png";
import logo2 from "./telegram-logo-2A32756393-seeklogo.com.png";
import logo3 from "./X_logo.jpg";
Modal.setAppElement("#root"); // Important for accessibility

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [toastVisible, setToastVisible] = useState(false);

  // Function to load data
  const loadData = async () => {
    setLoading(true);
    const snapshot = await getDocs(collection(db, "copies"));
    if (!snapshot.empty) {
      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(newData);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData(); // Load data on component mount
  }, []);

  // Function to select a random text and open the modal
  const handleButtonClick = () => {
    if (data.length > 0) {
      const randomItem = data[Math.floor(Math.random() * data.length)];
      openModal(randomItem.text);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      showToast(); // Show toast notification
    });
  };

  const openModal = (text) => {
    setSelectedText(text);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedText("");
  };

  const showToast = () => {
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 2000); // Hide after 2 seconds
  };

  return (
    <div className="app-container">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <button onClick={handleButtonClick} className="random-button">
          Copy Pasta
        </button>
      )}
      <div className="social-icons">
        <a
          href="https://dexscreener.com/solana/6fwmzn2oegxzeu1r4dnec1ev6qkzxh3upqqmvzfp9odh"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo1} alt="Dexscreener" />
        </a>
        <a
          href="https://t.me/copypastaportal"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo2} alt="Telegram" />
        </a>
        <a
          href="https://x.com/copypasta_sol?s=21"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo3} alt="Twitter" />
        </a>
      </div>
      {/* Modal component from react-modal */}
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Text Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
        shouldCloseOnOverlayClick={true} // Allows closing by clicking outside
      >
        <h3>{selectedText}</h3>
        <div className="modal-buttons">
          <button
            className="copy-button"
            onClick={() => copyToClipboard(selectedText)}
          >
            Copy
          </button>
          <button className="modal-close" onClick={closeModal}>
            Close
          </button>
        </div>
      </Modal>

      {/* Toast Notification */}
      {toastVisible && <div className="toast show">Copied to clipboard!</div>}
    </div>
  );
}

export default App;
